import { computed } from "@vue/composition-api";

import useFormState from "../state";
import store from "../../../store";
import useFormDocumentation from ".";
import axios from "axios";

export default function useFormActions(props, context) {
  const formState = useFormState(props);

  const authAccessToken = computed(() => {
    return store.getters.accessToken;
  });

  async function submit() {
    formState.buttons.value.submit.loading = true;

    const documentation = useFormDocumentation(props, context);

    await documentation.prepareOrderIntakePayload();

    let additionalHeaders = {};
    if (authAccessToken.value) {
      additionalHeaders["Authorization"] = `Bearer ${authAccessToken.value}`;
    }

    const reader = new FileReader();
    reader.onload = () => {
      console.log(reader.result);
    };
    reader.readAsText(formState.orderIntake.value.formData.get("answers"));

    const request = axios.request({
      url: props.form.api.documentOrder.url,
      method: props.form.api.documentOrder.method,
      data: formState.orderIntake.value.formData,
      headers: {
        ...props.form.api.documentOrder.headers,
        ...additionalHeaders,
      },
    });

    const promise = new Promise((resolve, reject) => {
      request
        .then((response) => {
          resolve(response);
        })
        .catch((e) => {
          formState.summary.value = false;
          reject(e);
        })
        .finally(() => {
          formState.buttons.value.submit.loading = false;

          window.scrollTo(0, 0);
          window.top.postMessage(
            {
              action: "submit",
            },
            "*"
          );
        });
    });

    context.emit("submit", promise);
  }

  return {
    submit,
  };
}
