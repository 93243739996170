<template>
  <div v-show="isVisible">
    <b-form-group label-size="lg">
      <template v-slot:label>
        <span v-html="itemTitle"></span
        ><sup v-if="item.required" class="gid-asterisk">*</sup>
      </template>
      <p v-if="item.errors.length" class="lead">
        <span v-for="error in item.errors" :key="error" class="gid-error"
          >{{ $t(`errors.${item.name}.${error}`) }}&nbsp;</span
        >
      </p>
      <p
        v-if="item.explanation"
        v-html="item.explanation"
        class="lead__explanation"
      ></p>
      <b-form-datepicker
        v-model="answerWizardItem"
        :name="item.name"
        :locale="this.$store.getters.formSettings.locale"
        :min="minDate"
        :max="maxDate"
        @change="changeAction"
      >
        <span class="lead float-right" v-if="item.suffix">{{
          item.suffix
        }}</span>
      </b-form-datepicker>
    </b-form-group>
  </div>
</template>

<script>
import { ANSWER_SET } from "@/store/form.module/types";
import WizardItemMixin from "@/components/mixins/wizardItem.js";

export default {
  name: "WizardItemText",
  mixins: [WizardItemMixin],
  props: {
    item: {
      type: Object,
      required: true,
    },
    processPricing: {
      type: Function,
      required: false,
    },
  },
  computed: {
    minDate() {
      return this.item.name == "report-date"
        ? this.$store.getters.completeOrder.job.appointment_start_timestamp
        : null;
    },
    maxDate() {
      return this.item.name == "report-date"
        ? this.$store.getters.completeOrder.job.appointment_end_timestamp
        : null;
    },
    answerWizardItem: {
      get() {
        return this.$store.getters.getAnswer(this.item.id);
      },
      set(answerWizardItem) {
        this.$store.dispatch(ANSWER_SET, {
          answer: answerWizardItem,
          id: this.item.id,
          errors: [],
          jobFieldPopulated: this.item.populatedJobField,
        });
      },
    },
    itemTitle() {
      return this.item.title;
    },
    isVisible() {
      return this.doesItemDependsOnSomething();
    },
  },
  methods: {},
  created() {},
};
</script>
