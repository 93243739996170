/* eslint-disable no-unused-vars */
import { computed } from "@vue/composition-api";
import store from "../../store";
import axios from "axios";
import {
  WIZARD_ITEMS_SET,
  FLOWS_SET,
  PLANNING_OPPORTUNITY_SET,
  FORM_SETTINGS_SET,
  TRANSLATION_SET,
  FORM_ERRORS_SET,
  ORDER_SET,
  AUTH_SET,
  AFFILIATE_ID_SET,
  EXTERNAL_INVOICING_ID_SET,
  ANSWER_APPEND,
  BRAND2_SET,
  OWN_INSTALLER_SET,
  EID_SET,
  PROJECT_ID_SET,
} from "@/store/form.module/types";
import useModuleState from "./state";
import i18n from "../../i18n";
import { POPULATE_QUESTION } from "../../store/form.module/types";
import { FormStrategyEnum } from "@gid/models";

export default function useForm(props) {
  const moduleState = useModuleState(props);

  // computed properties
  const formFetched = computed(() => {
    if (!moduleState.form.value.fetched.items.length) {
      return false;
    }
    return (
      moduleState.form.value.fetched.items.every((x) => x.fetched) &&
      moduleState.form.value.fetched.general
    );
  });

  const formSettings = computed(() => {
    return store.getters.formSettings;
  });

  // methods
  function fetchForm() {
    const args = [];
    if (props.orderId) {
      args.push(["order-id", props.orderId]);
    }
    const params = new URLSearchParams(args);
    const request = axios.get(moduleState.form.value.api.retrieveForm.url, {
      headers: moduleState.form.value.api.retrieveForm.headers,
      params: params,
    });

    moduleState.form.value.fetched.loading = true;

    request
      .then(({ data }) => {
        store.dispatch(PLANNING_OPPORTUNITY_SET, {
          opportunity: data.form.opportunity,
        });

        store.dispatch(TRANSLATION_SET, data.form.translation);

        i18n.mergeLocaleMessage(
          data.form.settings.locale,
          data.form.translation
        );

        if (
          [
            FormStrategyEnum.MODIFICATION,
            FormStrategyEnum.DOCUMENTATION,
          ].includes(data.form.settings.orderStrategy.mode)
        ) {
          store.dispatch(ORDER_SET, {
            id: data.form.order.id,
            isPrecheck: data.form.order.isPrecheck,
            services: data.form.order.services,
            products: data.form.order.products,
            completeOrder: data.form.order.completeOrder,
          });
        }

        store.dispatch(AUTH_SET, {
          accessToken: props.accessToken,
        });
        store.dispatch(AFFILIATE_ID_SET, props.affiliateId);
        store.dispatch(EXTERNAL_INVOICING_ID_SET, props.externalInvoicingId);
        store.dispatch(EID_SET, props.eid);
        store.dispatch(PROJECT_ID_SET, props.projectId);
        store.dispatch(BRAND2_SET, props.brand2);
        store.dispatch(OWN_INSTALLER_SET, props.ownInstaller);

        store.dispatch(FLOWS_SET, { flows: data.form.flows });

        const wizardItems = [];
        for (let i = 0; i < data.form.flows.length; i++) {
          const flow = data.form.flows[i];
          for (let j = 0; j < flow.items.length; j++) {
            const item = flow.items[j];

            moduleState.form.value.fetched.items.push({
              id: item.id,
              fetched: true,
            });

            wizardItems.push({ ...item });
          }
        }

        store.dispatch(WIZARD_ITEMS_SET, {
          wizardItems,
        });

        moduleState.form.value.name = data.form.name;
        if (data.form.settings) {
          moduleState.form.value.settings = { ...data.form.settings };
        }
        if (data.form.opportunity) {
          moduleState.form.value.opportunity = { ...data.form.opportunity };
        }
        if (props.routeParams) {
          props.routeParams.forEach((param) => {
            const globalIndex = wizardItems.findIndex(
              (item) => Object.keys(param)[0] === item.name
            );
            if (globalIndex != -1) {
              const actionProp = {
                globalIndex,
                answer: Object.values(param)[0],
              };
              store.dispatch(POPULATE_QUESTION, actionProp);
            }
          });
        }
        store.dispatch(FORM_SETTINGS_SET, moduleState.form.value.settings);

        moduleState.form.value.fetched.general = true;
      })
      .catch((err) => {
        console.error(err);
        // eslint-disable-line
        moduleState.form.value.fetched.general = false;
      })
      .finally(() => {
        moduleState.form.value.fetched.loading = false;
        window.top.postMessage(
          {
            action: "formFetched",
            value: moduleState.form.value.fetched.general,
          },
          "*"
        );
      });
  }

  function getStarted(value) {
    moduleState.form.value.started = value;
  }

  function finish(value) {
    // eslint-disable-line
  }

  async function submit(promise) {
    promise
      .then((response) => {
        this.$gtag.pageview(`/${moduleState.form.value.id}/success/`);

        // eslint-disable-line
        moduleState.form.value.started = false;
        moduleState.form.value.finished = true;
        moduleState.order.value = response.data;

        const searchParams = new URLSearchParams(window.location.search);
        let successRedirect = searchParams.get("success-redirect");
        let successRedirectTarget = searchParams.get("success-redirect-target");
        if (successRedirect) {
          successRedirect += successRedirect.includes("?") ? "&" : "?";
          successRedirect += `import_id=${encodeURIComponent(
            response.data.job.import_id
          )}`;
          if (successRedirectTarget == "top") {
            window.top.location.href = successRedirect;
          } else if (successRedirectTarget == "parent") {
            window.parent.location.href = successRedirect;
          } else {
            window.location.href = successRedirect;
          }
        }

        // -----
        // Disable until SF sync issues are resolved
        // -----
        // if (response.data.opportunity.import_target_status == JobStatusEnum.PROPOSAL_APPROVAL_PENDING) {
        //   this.sendWithRetry(response.data.api_id);
        // }
      })
      .catch((error) => {
        moduleState.order.value = {};
        if (error.response) {
          if (error.response.status == 422) {
            store.dispatch(
              FORM_ERRORS_SET,
              error.response.data.errors.customer
            );
          } else {
            store.dispatch(FORM_ERRORS_SET, {
              general: [
                "Oops, there was an internal problem when receiving your order.",
              ],
            });
          }
        }
        moduleState.form.value.api.probe.retry.current = 0;
      });
  }

  return {
    // computed properties
    formFetched,
    formSettings,
    // methods
    fetchForm,
    getStarted,
    finish,
    submit,
  };
}
