/* eslint-disable no-unused-vars */
import useFormState from "../state";
import store from "../../../store";

export default function useFormOrder(props, context) {
  const formState = useFormState(props);

  async function processOrderPayload() {
    formState.orderIntake.value.order.job_id = store.getters.order.id;
    const file = new File(
      [JSON.stringify(formState.orderIntake.value.order)],
      "order",
      { type: "application/json" }
    );
    formState.orderIntake.value.formData.append("order", file);
  }

  function clearOrderIntakeOrderPayload() {
    formState.orderIntake.value.order = {
      job_id: null,
    };
  }
  return {
    processOrderPayload,
    clearOrderIntakeOrderPayload,
  };
}
