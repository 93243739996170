<template>
  <b-jumbotron header-level="6">
    <template #header>{{ title }}</template>
    <template #lead>{{ current.headline }}</template>
    <h6 v-if="current.description" v-html="current.description"></h6>
    <hr class="my-4" />
    <slot name="price"></slot>
    <template v-if="wizardItems">
      <wizard-item
        v-for="item in wizardItems"
        :key="item.id"
        :id="item.id"
        :processPricing="processPricing"
      />
    </template>
    <b-card
      class="my-5"
      v-if="emptyRequiredWizardItems && emptyRequiredWizardItems.length"
      no-body
      :header="$t('to-continue')"
    >
      <b-list-group flush>
        <b-list-group-item
          class="text-danger"
          v-for="item in emptyRequiredWizardItems"
          :key="item.id"
          v-html="item.title"
        ></b-list-group-item>
      </b-list-group>
    </b-card>
    <div v-if="formHasErrors">
      <span
        v-for="(error, index) in formErrors"
        :key="index"
        class="gid-error"
        v-html="error"
      ></span>
    </div>
    <div>
      <div class="text-center buttons-container">
        <p class="lead text-muted">
          <span class="gid-asterisk">*</span> {{ $t("mandatory-field") }}
        </p>
        <b-button-group>
          <!-- previous -->
          <b-button
            v-if="!$store.getters.isFirstFlow(flowStep)"
            :disabled="$store.getters.hasLoadingFileTypeItems"
            variant="outline-secondary"
            class="previous"
            @click="previousFlowStep()"
          >
            <b-spinner
              v-if="buttons.previous.loading"
              class="gid-spinner--button mr-2"
            ></b-spinner>
            {{ $t("form.buttons.previous.name") }}
          </b-button>

          <!-- next -->
          <b-button
            v-if="!$store.getters.isLastFlow(flowStep) && !isModification"
            :disabled="
              !$store.getters.hasAllAnsweredFlowItems(current) ||
              $store.getters.hasLoadingFileTypeItems
            "
            class="next"
            variant="secondary"
            @click="nextFlowStep()"
          >
            <b-spinner
              v-if="buttons.next.loading"
              class="gid-spinner--button mr-2"
            ></b-spinner>
            {{ $t("form.buttons.next.name") }}
          </b-button>
          <b-button
            v-else-if="!$store.getters.isLastFlow(flowStep) && isModification"
            :disabled="
              buttons.preSave.loading ||
              !$store.getters.hasAllAnsweredFlowItems(current) ||
              $store.getters.hasLoadingFileTypeItems
            "
            class="pre-save"
            variant="secondary"
            @click="preSaveFlowStep({ direction: 'next' })"
          >
            <b-spinner
              v-if="buttons.preSave.loading"
              class="gid-spinner--button mr-2"
            ></b-spinner>
            {{ $t(`form.buttons.pre-save-and-next.name`) }}
          </b-button>

          <!-- summary -->
          <b-button
            v-if="
              $store.getters.isLastFlow(flowStep) &&
              hasSummary &&
              !isModification
            "
            :disabled="
              !$store.getters.hasAllAnsweredFlowItems(current) ||
              $store.getters.hasLoadingFileTypeItems
            "
            class="mr-2 summary"
            variant="primary"
            @click="toSummary()"
          >
            <b-spinner
              v-if="buttons.summary.loading"
              class="gid-spinner--button mr-2"
            ></b-spinner>
            {{ $t("form.buttons.summary.name") }}
          </b-button>
          <b-button
            v-else-if="$store.getters.isLastFlow(flowStep) && isModification"
            :disabled="
              buttons.preSave.loading ||
              !$store.getters.hasAllAnsweredFlowItems(current) ||
              $store.getters.hasLoadingFileTypeItems
            "
            class="pre-save"
            variant="secondary"
            @click="preSaveFlowStep({ direction: 'summary' })"
          >
            <b-spinner
              v-if="buttons.preSave.loading"
              class="gid-spinner--button mr-2"
            ></b-spinner>
            {{ $t("form.buttons.summary.name") }}
          </b-button>

          <slot name="buttons"></slot>
        </b-button-group>
      </div>
    </div>
  </b-jumbotron>
</template>

<script>
import WizardItem from "@/components/WizardItem";
import { HISTORY_FLOWS_ADD } from "@/store/form.module/types";
import { FormStrategyEnum } from "@gid/models";

export default {
  name: "WizardFlow",
  components: {
    WizardItem,
  },
  data() {
    return {};
  },
  computed: {
    current() {
      return this.flows.find((x) => x.step === this.flowStep);
    },
    visibleItems() {
      return (currentId) => {
        return this.$store.getters.visibleWizardItems(currentId);
      };
    },
    flows() {
      return this.$store.getters.flowsByOpportunitySfid;
    },
    wizardItems() {
      return this.$store.getters.getFlowByOpportunitySfidAndStep(this.flowStep)
        .items;
    },
    emptyRequiredWizardItems() {
      return this.$store.getters.visibleWizardItemsNotAnswered(this.current);
    },
    settings() {
      return this.$store.state.settings;
    },
    formHasErrors() {
      return (
        this.$store.getters.isLastFlow(this.flowStep) &&
        this.$store.state.form.errors.length
      );
    },
    formErrors() {
      return this.$store.state.form.errors;
    },
    hasSummary() {
      return this.$store.getters.formSettings.summary;
    },
    isModification() {
      return (
        this.$store.getters.formSettings?.orderStrategy?.mode ==
        FormStrategyEnum.MODIFICATION
      );
    },
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    flowStep: {
      type: Number,
      required: true,
    },
    processPricing: {
      type: Function,
      required: false,
    },
    buttons: {
      type: Object,
      required: true,
    },
  },
  methods: {
    nextFlowStep() {
      this.$emit("nextFlowStep");
    },
    previousFlowStep() {
      this.$emit("previousFlowStep");
    },
    preSaveFlowStep(options) {
      this.$emit("preSaveFlowStep", options);
    },
    toSummary() {
      this.$store.state.form.errors = [];
      this.$emit("toSummary");
    },
  },
  created() {
    this.$store.dispatch(HISTORY_FLOWS_ADD, this.current.id);
  },
};
</script>
