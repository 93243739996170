// common
import useFormMetadata from "../metadata";
import useFormHash from "../hash";
import useFormOrderBase from "../order";
import useFormAnswers from "../answers";

// modification
import useFormOrder from "./order";

export default function useFormDocumentation(props, context) {
  const { generateHash, clearOrderIntakeHash } = useFormHash(props);

  const { processAnswersPayload } = useFormAnswers(props, context);

  const { processMetadataPayload, processFilesMetadataPayload } =
    useFormMetadata(props, context);

  const { clearOrderIntakeFormData } = useFormOrderBase(props, context);

  const { processOrderPayload, clearOrderIntakeOrderPayload } = useFormOrder(
    props,
    context
  );

  // methods
  async function prepareOrderIntakePayload() {
    clearOrderIntake();
    generateHash();
    processOrderPayload();
    processAnswersPayload();
    processMetadataPayload();
    processFilesMetadataPayload();
  }

  function clearOrderIntake() {
    clearOrderIntakeHash();
    clearOrderIntakeOrderPayload();
    clearOrderIntakeFormData();
  }

  return {
    // methods
    prepareOrderIntakePayload,
    clearOrderIntake,
  };
}
