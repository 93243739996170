/* eslint-disable no-unused-vars */
import { computed } from "@vue/composition-api";
import store from "../../store";
import axios from "axios";
import useModuleState from "./state";
import useDebounce from "./debounce";
import {
  PRICING_REQUEST_ADD,
  PRICING_REQUEST_REMOVE,
  PRICING_PRICE_SET,
  PRICING_PRICE_MIN_SET,
  PRICING_PRICE_MAX_SET,
} from "@/store/form.module/types";
import CryptoJS from "crypto-js";
import { OrderFormMappingModeEnum } from "@gid/models";

export default function useMapping(props) {
  const moduleState = useModuleState(props);

  const { debounce } = useDebounce();

  const debounceTimeInMs = 300; // miliseconds

  // computed properties
  const isMappingFormula = computed(() => {
    return (
      store.getters.formSettings.mapping &&
      store.getters.formSettings.mapping.mode ==
        OrderFormMappingModeEnum.FORMULA
    );
  });

  const showPrice = computed(() => {
    if (store.getters.formSettings.price == false) {
      return false;
    }
    if (
      new URLSearchParams(window.location.search).has("affiliate-id") ||
      new URLSearchParams(window.location.search).has("affiliate_id")
    ) {
      return store.getters.formSettings.priceAffiliate;
    }
    return true;
  });

  function generateChecksum(str) {
    return CryptoJS.MD5(str).toString();
  }

  // methods
  function getMappingItems() {
    return store.getters.allWizardItemsByOpportunitySfid
      .filter((x) => !["file", "signature"].includes(x.type))
      .map((x) => {
        return { key: x.name, value: x.answer };
      });
  }

  async function processMapping(helpers) {
    const body = {
      formRef: props.formRef,
      opportunityId: props.opportunityId,
      items: getMappingItems(),
      orderId: store.getters.order.id,
    };
    try {
      const { data } = await axios.post(
        moduleState.form.value.api.mapping.url,
        body,
        { headers: moduleState.form.value.api.mapping.headers }
      );
      if (data.services) {
        data.services.forEach((x) => {
          helpers.addService(x);
        });
      }
      if (data.products) {
        data.products.forEach((x) => {
          helpers.addProduct(x);
        });
      }
      if (data.professionalComment) {
        helpers.addProfessionalComments(data.professionalComment);
      }
    } catch (err) {
      console.error(err);
    }
  }

  function getMappingItemsForPricing() {
    return new Promise(function (resolve, reject) {
      setTimeout(function () {
        let mappingItems = getMappingItems();
        resolve(mappingItems);
      }, debounceTimeInMs);
    });
  }

  function processPricing(helpers) {
    getMappingItemsForPricing().then((mappingItems) => {
      if (mappingItems) {
        const url = moduleState.form.value.api.pricing.url;
        const method = "post";
        const headers = moduleState.form.value.api.pricing.headers;
        const body = {
          formRef: props.formRef,
          opportunityId: props.opportunityId,
          items: mappingItems,
          orderId: store.getters.order.id,
        };

        const request = {
          url,
          method,
          headers,
          body,
          helpers,
          at: +new Date(),
          checksum: generateChecksum(JSON.stringify(body)),
        };
        store.dispatch(PRICING_REQUEST_ADD, request);
      }
    });
  }

  const unsubscribePricingRequests = store.subscribe((mutation, state) => {
    if (mutation.type === "PRICING_REQUEST_ADD") {
      debounce(
        () => dispatchPricingRequest(mutation, state),
        debounceTimeInMs
      )();
    }
  });

  function dispatchPricingRequest(mutation, state) {
    const latestRequestTimestamp = Math.max.apply(
      Math,
      state.form.pricing.requests.map(function (x) {
        return x.at;
      })
    );
    const latestRequest = state.form.pricing.requests.find(
      (x) => x.at === latestRequestTimestamp
    );

    if (latestRequest) {
      store.dispatch(PRICING_REQUEST_REMOVE, latestRequest);
      dispatchHttpPricingRequest(latestRequest);
    }
  }

  function dispatchHttpPricingRequest(latestRequest) {
    axios
      .post(moduleState.form.value.api.pricing.url, latestRequest.body, {
        headers: moduleState.form.value.api.pricing.headers,
      })
      .then(({ data }) => {
        store.dispatch(PRICING_PRICE_SET, data.pricing ?? null);
        store.dispatch(PRICING_PRICE_MIN_SET, data.pricingMin ?? null);
        store.dispatch(PRICING_PRICE_MAX_SET, data.pricingMax ?? null);
      })
      .catch((err) => {
        console.error("Pricing Request has failed", err);
      });
  }

  return {
    // computed properties
    isMappingFormula,
    showPrice,
    // methods
    getMappingItems,
    processMapping,
    processPricing,
    unsubscribePricingRequests,
  };
}
