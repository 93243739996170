/* eslint-disable no-unused-vars */
import { computed } from "@vue/composition-api";
import useFormModification from "./";

import useFormState from "../state";
import useFormSummary from "../summary";
import useFormActionsBase from "../actions";

import store from "../../../store";
import axios from "axios";
import { FormOrderModificationThroughEnum } from "@gid/models";

export default function useFormActions(props, context) {
  const formState = useFormState(props);

  const { previousFlowStep, nextFlowStep } = useFormActionsBase(props, context);

  const { toSummary } = useFormSummary(props, context);

  // computed properties
  const formSettings = computed(() => {
    return store.getters.formSettings;
  });

  const authAccessToken = computed(() => {
    return store.getters.accessToken;
  });

  // methods
  async function submit(preSave = false) {
    formState.buttons.value.submit.loading = true;

    const extraContext = {
      ...context,
      preSave,
    };

    const modification = useFormModification(props, extraContext);

    await modification.prepareOrderIntakePayload();

    let additionalHeaders = {};
    if (authAccessToken.value) {
      additionalHeaders["Authorization"] = `Bearer ${authAccessToken.value}`;
    }
    const request = axios.request({
      url: props.form.api.modifyOrder.url,
      method: props.form.api.modifyOrder.method,
      data: formState.orderIntake.value.formData,
      headers: { ...props.form.api.modifyOrder.headers, ...additionalHeaders },
    });

    const promise = new Promise((resolve, reject) => {
      request
        .then((response) => {
          if (
            [
              FormOrderModificationThroughEnum.VALUE_CHANGE,
              FormOrderModificationThroughEnum.STATUS_CHANGE,
              FormOrderModificationThroughEnum.CANCELLATION_REASON,
            ].includes(formSettings.value.orderStrategy.modification.through)
          ) {
            const redirectPayload = {
              job: {
                api_id: response.data.job.api_id,
                sfid: response.data.job.sfid,
                status: response.data.job.status,
              },
            };
            window.top.postMessage(
              { action: "redirect", value: redirectPayload },
              "*"
            );
          }
          resolve(response);
        })
        .catch((e) => {
          formState.summary.value = false;
          reject(e);
        })
        .finally(() => {
          formState.buttons.value.submit.loading = false;

          modification.clearOrderIntake();

          window.scrollTo(0, 0);
          window.top.postMessage(
            {
              action: "scrollIntoView",
              value: { behavior: "smooth", block: "start" },
            },
            "*"
          );
        });
    });

    context.emit("submit", promise);
  }

  async function preSaveFlowStep(options) {
    formState.buttons.value.preSave.loading = true;

    const extraContext = {
      ...context,
      preSave: true,
    };

    const modification = useFormModification(
      { ...props, processMapping: null },
      extraContext
    );

    await modification.prepareOrderIntakePayload();

    let additionalHeaders = {};
    if (authAccessToken.value) {
      additionalHeaders["Authorization"] = `Bearer ${authAccessToken.value}`;
    }
    const request = axios.request({
      url: props.form.api.preSaveOrder.url,
      method: props.form.api.preSaveOrder.method,
      data: formState.orderIntake.value.formData,
      headers: { ...props.form.api.preSaveOrder.headers, ...additionalHeaders },
    });

    const promise = new Promise((resolve, reject) => {
      request
        .then((response) => {
          switch (options.direction) {
            case "previous":
              previousFlowStep();
              break;
            case "next":
              nextFlowStep();
              break;
            case "summary":
              toSummary();
              break;
          }
          resolve(response);
        })
        .catch((e) => {
          reject(e);
        })
        .finally(() => {
          formState.buttons.value.preSave.loading = false;

          modification.clearOrderIntake();

          window.scrollTo(0, 0);
          window.top.postMessage(
            {
              action: "scrollIntoView",
              value: { behavior: "smooth", block: "start" },
            },
            "*"
          );
        });
    });

    context.emit("preSave", promise);
  }

  return {
    submit,
    preSaveFlowStep,
  };
}
