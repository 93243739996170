/* eslint-disable no-unused-vars */
import store from "../../../store";

// common
import useFormState from "../state";
import useFormMapping from "../mapping";
import useFormMetadata from "../metadata";
import useFormHash from "../hash";
import useFormOrderBase from "../order";
import useFormAnswers from "../answers";

// modification
import useFormOrder from "./order";
import useFormPopulatedJobFields from "./populated-job-fields";

export default function useFormModification(props, context) {
  const formState = useFormState(props);

  const { generateHash, clearOrderIntakeHash } = useFormHash(props);

  const { processAnswersPayload } = useFormAnswers(props, context);

  const { processPopulatedJobFields } = useFormPopulatedJobFields(props);

  const { processMetadataPayload, processFilesMetadataPayload } =
    useFormMetadata(props, context);

  const { processOrderPayload, clearOrderIntakeOrderPayload } = useFormOrder(
    props,
    context
  );

  const { clearOrderIntakeFormData } = useFormOrderBase(props, context);

  const {
    addService,
    addProduct,
    addProfessionalComment,
    addProfessionalComments,
  } = useFormMapping(props);

  // methods
  async function prepareOrderIntakePayload() {
    sanitizeOrderIntakePayload();
    clearOrderIntake();
    generateHash();
    if (props.processMapping) {
      await props.processMapping({
        addService,
        addProduct,
        addProfessionalComments,
      });
    }
    processPopulatedJobFields();
    processSignature();
    await processOrderPayload();
    processAnswersPayload();
    processMetadataPayload();
    processFilesMetadataPayload();
  }

  function sanitizeOrderIntakePayload() {
    const data = store.getters.allWizardItems;
    const postalCodeIndex = data.findIndex((x) => x.name === "postal_code");
    if (postalCodeIndex > -1) {
      data[postalCodeIndex].answer = data[postalCodeIndex].answer?.replace(
        /\s/g,
        ""
      );
    }
  }

  function processSignature() {
    store.getters.wizardSignatureTypeItems.forEach((item) => {
      const answer = [item.answer].flat().filter((a) => a != null && a !== "");

      if (answer.length > 0) {
        answer.forEach((a) => {
          formState.orderIntake.value.formData.append(item.id, a);
        });
      }
    });
  }

  function clearOrderIntake() {
    clearOrderIntakeHash();
    clearOrderIntakeOrderPayload();
    clearOrderIntakeFormData();
  }

  return {
    // methods
    prepareOrderIntakePayload,
    clearOrderIntake,
  };
}
